export function arrowsCrossed(node, state) {
  const inputArrows = state.arrows.filter(arrow => arrow.to == node.id)
  if (inputArrows.length != 2) {
    return false
  }
  const arrow1 = inputArrows[0]
  const arrow2 = inputArrows[1]

  const values = [
    arrow1.x1, arrow1.x2, arrow1.y1, arrow1.y2,
    arrow2.x1, arrow2.x2, arrow2.y1, arrow2.y2
  ]
  if (values.filter(n => typeof(n) == 'undefined').length > 0) {
    return false
  }

  const a_12_11 = Math.atan2(arrow1.y2 - arrow1.y1, arrow1.x2 - arrow1.x1)
  const a_21_11 = Math.atan2(arrow2.y1 - arrow1.y1, arrow2.x1 - arrow1.x1)
  const a_22_11 = Math.atan2(arrow2.y2 - arrow1.y1, arrow2.x2 - arrow1.x1)
  const a_22_21 = Math.atan2(arrow2.y2 - arrow2.y1, arrow2.x2 - arrow2.x1)
  const a_11_21 = Math.atan2(arrow1.y1 - arrow2.y1, arrow1.x1 - arrow2.x1)
  const a_12_21 = Math.atan2(arrow1.y2 - arrow2.y1, arrow1.x2 - arrow2.x1)

  const pi = Math.PI
  const across21 = ((a_21_11 - a_12_11) + 3*pi) % (2*pi) - pi
  const across22 = ((a_22_11 - a_12_11) + 3*pi) % (2*pi) - pi
  const across11 = ((a_11_21 - a_22_21) + 3*pi) % (2*pi) - pi
  const across12 = ((a_12_21 - a_22_21) + 3*pi) % (2*pi) - pi

  return across21 * across22 < 0 && across11 * across12 < 0
}


