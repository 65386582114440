import React from 'react'
import classNames from 'classnames'
import { connect, useDispatch } from 'react-redux'
import OperatorButton from './OperatorButton'
import * as actions from './actions'
import operators from './operators'
import { flipOperatorShape } from './operatorShape'
import FileMenu from './FileMenu'

let Toolbar = ({ state }) => {
  const dispatch = useDispatch()
  let newRelation = () => { dispatch(actions.newRelationMode()) }

  let deleteRelation = () => {
    let confirmed = window.confirm('This will delete the selected item(s).')
    if (confirmed) {
      dispatch(actions.deleteSelected())
    }
  }

  let flipOperator = () => { dispatch(actions.flipOperator()) }
  let toggleBinary = () => { dispatch(actions.toggleBinary()) }
  // let toggleDataPane = () => { dispatch(actions.toggleDataPane()) }
  // const dataButtonLabel = (state.editor.dataPane ? 'Hide' : 'Show') + ' Data'

  const selectedNodes = state.nodes.filter(node => node.selected)
  const selectedArrows = state.arrows.filter(arrow => arrow.selected)
  const selectionSize = selectedNodes.length + selectedArrows.length
  const forceBinary = state.editor.action === 'binary'
  let flippable
  if (selectedNodes.length === 1) {
    let selectedNode = selectedNodes[0]
    flippable = selectedNode && selectedNode.operator && selectedNode.operator.shape && flipOperatorShape(selectedNode.operator.shape)
  } else {
    flippable = false
  }

  return (
    <header className="toolbar">
      <FileMenu/>

      { selectionSize === 0 &&
        <button onClick={newRelation} className={classNames({ operatorButton: true, selected: state.editor.action === 'new_relation' })}>
          <div className="iconContainer">
            <div className="relation" aria-hidden={true} style={{ boxSizing: 'border-box', height: '25px', width: '50px', padding: 1, margin: '5px 0' }}>
              &#10133;
            </div>
          </div>
          <label className="">New Relation</label>
        </button>
      }

      { selectionSize >= 1 &&
        <button onClick={deleteRelation} className="operatorButton">
          <div className="iconContainer">
            <div aria-hidden={true} style={{ fontSize: 20, lineHeight: '30px', color: 'white' }}>
              &#10060;
            </div>
          </div>
          <label className="">Delete</label>
        </button>
      }

      { flippable &&
        <button onClick={flipOperator} className="operatorButton">
          <div className="iconContainer">
            <div aria-hidden={true} style={{ fontSize: 30, lineHeight: '30px', color: 'white' }}>
              &#8646;
            </div>
          </div>
          <label className="">Flip</label>
        </button>
      }

      {
        operators.filter(operator =>
          (operator.numInputs === selectedNodes.length && !forceBinary) ||
          (operator.numInputs === 2 && forceBinary)
        ).map(operator =>
          <OperatorButton key={operator.type} {...operator}/>
        )
      }

      { selectionSize === 1 &&
        <button onClick={toggleBinary} className="operatorButton">
          <div className="iconContainer">
            <div aria-hidden={true} style={{ fontSize: 30, lineHeight: '30px', color: 'white' }}>
              { forceBinary ? '\u2193' : '\u21ca' }
            </div>
          </div>
          <label className="">{ forceBinary ? 'Unary' : 'Binary' }</label>
        </button>
      }

      { /*
        <button onClick={toggleDataPane} className="operatorButton" style={{ marginLeft: 'auto' }}>
          <div aria-hidden={true} style={{ fontSize: 30, color: 'white', height: '25px', width: '50px', padding: 4, margin: '-6px 0 8px' }}>
            &#9638;
          </div>
          <label className="">{dataButtonLabel}</label>
        </button>
      */ }
    </header>
  )
}

Toolbar = connect(state => ({state}))(Toolbar);

export default Toolbar
